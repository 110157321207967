

import animate from './../global/animate';


export default class AriaExpand {

	static OPEN = 'OPEN';
	static CLOSED = 'CLOSED';

	constructor(toggle, el, expandClass = 'is-open', callback = () => {}) {

		this.toggle = (toggle instanceof jQuery) ? toggle.get(0) : toggle;
		this.el = (el instanceof jQuery) ? el.get(0) : el;
		this.expandClass = expandClass;
		this.callback = callback;
		this.openedElem = null;

		// setup click handler
		this.toggle.addEventListener('click', this.clickHandler.bind(this));

		// set initial state from aria attribute

		if (this.toggle.getAttribute('aria-expanded') === 'true') {
			this.toggle.setAttribute('aria-expanded', 'true');
			this.toggle.classList.add(this.expandClass);
			this.el.setAttribute('aria-hidden', 'false');
			this.el.setAttribute('tabindex', '0');
			this.el.classList.add(this.expandClass);
		} else {
			this.toggle.setAttribute('aria-expanded', 'false');
			this.toggle.classList.remove(this.expandClass);
			this.el.setAttribute('aria-hidden', 'true');
			this.el.setAttribute('tabindex', '-1');
			this.el.classList.remove(this.expandClass);
		}
	}

	isOpen() {
		return (this.toggle.getAttribute('aria-expanded') === 'true');
	}

	close(callback = function(){}) {
		// put focus back to the original opening element
		this.openedElem && this.openedElem.focus();
		this.el.setAttribute('tabindex', '-1');
		callback(AriaExpand.CLOSED, this);
		this.callback && this.callback(AriaExpand.CLOSED, this);
		this.toggle.setAttribute('aria-expanded', 'false');
		this.toggle.classList.remove(this.expandClass);
		this.el.setAttribute('aria-hidden', 'true');
		animate.slideUp(this.el, this.expandClass);
	}

	open(callback = function(){}) {
		this.el.setAttribute('tabindex', '0');
		callback(AriaExpand.OPEN, this);
		this.callback && this.callback(AriaExpand.OPEN, this)
		this.toggle.setAttribute('aria-expanded', 'true');
		this.toggle.classList.add(this.expandClass);
		this.el.setAttribute('aria-hidden', 'false');
		animate.slideDown(this.el, this.expandClass);
		this.el.focus();
	}

	clickHandler(event) {

		// do default action if a meta/command key is pressed
		if (event.metaKey || event.shiftKey || event.ctrlKey || event.altKey) return;
		
		event.preventDefault();

		if (this.isOpen()) {
			this.close(this.callback);
			this.openedElem = null;
		} else {
			this.open(this.callback);
			this.openedElem = event.currentTarget;
		}
		
		return false;
	}
}